import React from 'react';
import Helmet from 'react-helmet';

export default function SEO(props) {
	const { name, title, description, image, keywords, slug, type } = props;
	const url = `${'https://growmylife.com.au'}${type ? `/${type}` : ''}${
		slug ? `/${slug}` : ''
	}`;

	const imageUrl = `${image ? image : ''}`;

	const seoTitle = title.replace('[title]', name ? name : '');

	return (
		<Helmet
			htmlAttributes={{ lang: 'en' }}
			meta={[
				{
					name: 'description',
					content: description,
				},
				{
					name: 'keywords',
					content: keywords,
				},
				{
					property: 'og:title',
					content: seoTitle,
				},
				{
					property: 'og:description',
					content: description,
				},
				{
					property: 'og:type',
					content: 'website',
				},
				{
					name: 'twitter:title',
					content: seoTitle,
				},
				{
					name: 'twitter:description',
					content: description,
				},
				{
					name: 'twitter:card',
					content: description,
				},
				{
					name: 'twitter:creator',
					content: 'Paul Cooper',
				},
			].concat(
				image
					? [
							{
								name: 'image',
								property: 'og:image',
								content: imageUrl,
							},
							{
								property: 'og:image:width',
								content: '1200',
							},
							{
								property: 'og:image:height',
								content: '628',
							},
							{
								name: 'twitter:card',
								content: 'summary_large_image',
							},
					  ]
					: [
							{
								name: 'twitter:card',
								content: 'summary',
							},
					  ]
			)}
		>
			{/* General tags */}
			<title>{seoTitle}</title>
			<meta name='description' content={description} />
			<meta name='image' content={imageUrl} />
			<link rel='canonical' href={slug} />

			{/* OpenGraph tags */}
			<meta property='og:url' content={slug} />
			<meta property='og:type' content='website' />
			<meta property='og:title' content={seoTitle} />
			<meta property='og:description' content={description} />
			<meta property='og:image' content={imageUrl} />

			{/* Twitter Card tags */}
			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:title' content={seoTitle} />
			<meta name='twitter:description' content={description} />
			<meta name='twitter:image' content={imageUrl} />
		</Helmet>
	);
}
