import React, { useState } from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import MetaTags from 'react-meta-tags';
import Container from '@material-ui/core/Container';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReactHtmlParser from 'react-html-parser';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import TimelineIcon from '@material-ui/icons/TimelineSharp';
import * as styles from '../styles/Activity.module.css';
import Seo from '../components/SeoComponent';

import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	LineShareButton,
	EmailShareButton,
} from 'react-share';
import {
	FacebookIcon,
	TwitterIcon,
	WhatsappIcon,
	LineIcon,
	EmailIcon,
} from 'react-share';
import { red, blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import YouTube from 'react-youtube';
import { Hidden } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ImagePlaceholder from '../images/Imageplaceholder.png';
import { GatsbyImage } from 'gatsby-plugin-image';
import Markdown from 'react-markdown';

//graphQL
export const query = graphql`
	query ActivityTemplateQuery($activityId: String!) {
		strapiActivities(activityId: { eq: $activityId }) {
			id
			activityId
			duration
			frequency
			imageURL
			GrowthAreas {
				WakeUpRefreshed
				Strengths
				Spirit
				SelfImage
				Purpose
				Relationships
				LifeGoals
				Hobbies
				Health
				FriendCircle
				Focus
				Diet
				Career
			}
			AttributeGroup {
				IndividualGroup
				IndoorOutdoor
				RepeatedOnetime
			}
			ReferencesGroup {
				URL
			}
			title
			researchValidated
			references
			publish_to_app
			providers {
				url
				name
				description
			}
			providerGroup {
				Description
				Name
				URL
				id
			}
			method
			mediaURL
			description
			defaultFrequency
			benefit
			Image {
				localFile {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
	}
`;

export default function Activity(props) {
	const { data, errors } = props;
	const activity = data.strapiActivities;
	if (errors) {
		return <Layout>{errors}</Layout>;
	}

	function url_domain(data) {
		if (typeof window !== 'undefined') {
			var a = document.createElement('a');
			a.href = data;
			return data.startsWith('www') ? data : a.hostname;
		} else return data;
	}
	let image;
	try {
		if (activity.imageURL) {
			image = activity.imageURL;
		}
	} catch (e) {
		image = { ImagePlaceholder };
	}

	return (
		<Layout>
			{/* <div>{JSON.stringify(data.strapiActivities)}</div> */}
			{/* SEO */}
			<MetaTags>
				<meta
					name='description'
					content={ReactHtmlParser(activity.description)}
				/>
				<meta property='og:title' content={activity.title} />
				{/* <meta property='og:image' content={image} /> */}
			</MetaTags>
			<Seo
				title={activity.title}
				description={ReactHtmlParser(activity.description)}
				// keywords={sanityArticles.content.keywords}
				image={image}
				slug={typeof window !== 'undefined' ? window.location.href : ''}
				type='activity'
			></Seo>
			<div className={styles.header}>
				{activity?.Image?.localFile?.childImageSharp?.gatsbyImageData ? (
					<GatsbyImage
						image={activity.Image.localFile.childImageSharp.gatsbyImageData}
						alt={activity.title}
						className={styles.image}
					/>
				) : (
					<img src={image} alt={activity.title} className={styles.image} />
				)}
			</div>

			<div className={styles.attContainer}>
				{activity.activityOwnerId != null ? null : (
					<div>
						<span
							style={{
								fontWeight: '400',
								fontSize: '0.9em',
								marginRight: '0.2em',
							}}
						>
							{activity.duration !== null ? (
								<span> {activity.duration} min</span>
							) : null}
						</span>
						<span>
							{activity?.AttributeGroup?.IndividualGroup === 'Both' ? (
								<span key='IndividualGroup' className={styles.attrtext}>
									{' '}
									- Individual - Group
								</span>
							) : (
								<span
									key={activity?.AttributeGroup?.IndividualGroup}
									className={styles.attrtext}
								>
									{' '}
									- {activity?.AttributeGroup?.IndividualGroup}
								</span>
							)}
						</span>
						<span>
							{activity?.AttributeGroup?.IndoorOutdoor === 'Both' ? (
								<span key='IndoorOutdoor' className={styles.attrtext}>
									{' '}
									- Indoor - Outdoor
								</span>
							) : (
								<span key='IndoorOutdoor' className={styles.attrtext}>
									{' '}
									- {activity?.AttributeGroup?.IndividualGroup}
								</span>
							)}
						</span>
						<span>
							{activity?.AttributeGroup?.RepeatedOnetime ? (
								<span key='RepeatedOnetime' className={styles.attrtext}>
									{' '}
									- {activity?.AttributeGroup?.RepeatedOnetime}
								</span>
							) : null}
						</span>
					</div>
				)}
			</div>
			<div className={styles.activityContainer}>
				<Container maxWidth='md'>
					<center>
						{activity.title ? (
							<div className={styles.goalTitle}>{activity.title}</div>
						) : null}
						<div className={styles.activityBenefit}>
							{ReactHtmlParser(activity.description)}
						</div>
					</center>

					<div className={styles.chipContainer}>
						{activity.GrowthAreas &&
							Object.entries(activity.GrowthAreas)
								.filter(([key, value]) => value !== 'None')
								.map(([key, value]) => (
									<div key={key}>
										<div className={styles.goallbl3}>{key}</div>
									</div>
								))}
					</div>

					<div className={styles.activityDescription}>
						<div className={styles.activityBenefit}>
							{ReactHtmlParser(activity.benefit)}
						</div>
					</div>
				</Container>
				{/* Scheduling Information Starts Here */}

				{/* Cards Information Starts Here */}
				<Container maxWidth='lg'>
					<Grid container spacing={1}>
						{activity.method !== null ? (
							<Grid item xs={12} sm={6}>
								<Card className={styles.main}>
									<div className={styles.activityMethod}>
										<span>
											{' '}
											<h2 className={styles.activityHeading}>How to do it</h2>
											<div className={styles.activityMethod}>
												{/* {ReactHtmlParser(activity.method)} */}
												<Markdown
													children={activity.method}
													escapeHtml={false}
												/>
											</div>
										</span>
									</div>
								</Card>
							</Grid>
						) : null}
						{activity.mediaURL !== undefined && activity.mediaURL !== null ? (
							<Grid item xs={12} sm={6}>
								<Card className={styles.main}>
									<>
										<h2 className={styles.activityHeading}>Watch this video</h2>

										<div className={styles.video}>
											<YouTube
												videoId={activity.mediaURL}
												opts={{ height: '300', width: '100%' }}
												// onReady={this._onReady}
											/>
										</div>
									</>
								</Card>
							</Grid>
						) : null}
						<Grid item xs={12} sm={12} spacing={3}>
							{activity?.providers !== undefined &&
							activity.providers?.length > 0 ? (
								<Card className={styles.mainAlt}>
									<div>
										<h1 className={styles.activityHeading}>
											Wellbeing Partners
										</h1>
										{activity.providers.map((item) => {
											return (
												<div key={item.name}>
													<center>
														<div style={{ margin: '1em 0' }}>
															<b>{item.name}</b>
														</div>
														{item.description} <br></br>
														<div className={styles.linktxt}>
															Visit{' '}
															<a
																key={item}
																href={
																	item.url.startsWith('www')
																		? '//' + item.url
																		: item.url
																}
																rel='noreferrer'
																target='_blank'
															>
																{url_domain(item.url)}
															</a>
														</div>
													</center>
												</div>
											);
										})}
									</div>
								</Card>
							) : null}
						</Grid>
						<Grid item xs={12} sm={12}>
							{/* <Card className={styles.main}> */}
							{activity.references && activity.references.length ? (
								<div className={styles.activityMethod}>
									<h2 className={styles.activityHeadingSource}>Our sources</h2>
									{activity.references.map((item) => {
										return (
											<div
												key={item}
												style={{ overflow: Hidden, color: '#000' }}
											>
												<LinkIcon
													fontSize='small'
													style={{
														color: '#000',
														verticalAlign: 'bottom',
													}}
												></LinkIcon>
												<a
													key={item}
													className={styles.linktxt}
													href={item.startsWith('www') ? '//' + item : item}
													target='_blank'
													rel='noreferrer'
												>
													{url_domain(item)}
												</a>
											</div>
										);
									})}
								</div>
							) : null}
							{/* </Card> */}
						</Grid>
					</Grid>
				</Container>
				{activity.activityOwnerId != null ? null : (
					<div style={{ marginTop: '20px' }}>
						<div className={styles.social}>
							<FacebookShareButton
								url={`https://growmylife.com.au/activity/${activity.title
									.replace(/:/g, '')
									.replace(/\s+/g, '-')
									.toLowerCase()}`}
								className={styles.socialIcons}
							>
								{' '}
								<FacebookIcon size={40} round={true} />
							</FacebookShareButton>{' '}
							<TwitterShareButton
								url={`https://growmylife.com.au/activity/${activity.title
									.replace(/:/g, '')
									.replace(/\s+/g, '-')
									.toLowerCase()}`}
								className={styles.socialIcons}
							>
								{' '}
								<TwitterIcon size={40} round={true} />
							</TwitterShareButton>
							<LineShareButton
								url={`https://growmylife.com.au/activity/${activity.title
									.replace(/:/g, '')
									.replace(/\s+/g, '-')
									.toLowerCase()}`}
								className={styles.socialIcons}
							>
								{' '}
								<LineIcon size={40} round={true} />
							</LineShareButton>
							<WhatsappShareButton
								url={`https://growmylife.com.au/activity/${activity.title
									.replace(/:/g, '')
									.replace(/\s+/g, '-')
									.toLowerCase()}`}
								className={styles.socialIcons}
							>
								{' '}
								<WhatsappIcon size={40} round={true} />
							</WhatsappShareButton>
							<EmailShareButton
								url={`https://growmylife.com.au/activity/${activity.title
									.replace(/:/g, '')
									.replace(/\s+/g, '-')
									.toLowerCase()}`}
								className={styles.socialIcons}
							>
								{' '}
								<EmailIcon size={40} round={true} />
							</EmailShareButton>
						</div>
					</div>
				)}
				{/* <Snackbar
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					open={this.state.snackbarOpen}
					onClose={this.handleClose}
					autoHideDuration={6000}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
				>
					<SnackbarContent
						className={styles.snackbarRoot}
						onClose={this.handleClose}
						message={this.state.snackMessage}
						action={
							<React.Fragment>
								<IconButton
									size='small'
									aria-label='close'
									color='inherit'
									onClick={this.handleClose}
								>
									<CloseIcon fontSize='small' />
								</IconButton>
							</React.Fragment>
						}
					/>
				</Snackbar> */}
			</div>

			<div style={{ backgroundColor: '#fff' }}>
				<div className={styles.fab}>
					<div className={styles.footersticky}>
						<Button
							className={styles.saveButton}
							size='medium'
							variant='contained'
							color='secondary'
							target='_blank'
							href='https://app.growmylife.com.au/mika'
						>
							{' '}
							Start your growth journey
						</Button>
					</div>
				</div>
			</div>
		</Layout>
	);
}
