// extracted by mini-css-extract-plugin
export var contributionLbl = "Activity-module--contributionLbl--1x3sI";
export var socialIcons = "Activity-module--socialIcons--3BOj6";
export var social = "Activity-module--social--3WJYj";
export var linktxt = "Activity-module--linktxt--2z6c1";
export var video = "Activity-module--video--iagnf";
export var splashScreen = "Activity-module--splashScreen--3mxv0";
export var loadingDot = "Activity-module--loadingDot--3iaYp";
export var run = "Activity-module--run--1pXOE";
export var title = "Activity-module--title--2iyDq";
export var inline = "Activity-module--inline--tYYrl";
export var redColor = "Activity-module--redColor--1d98V";
export var PrintIcon = "Activity-module--PrintIcon--1ACzY";
export var iconlbl = "Activity-module--iconlbl--WBHPE";
export var pulse = "Activity-module--pulse--26FNY";
export var pulse2 = "Activity-module--pulse2--2EHMA";
export var activityImage = "Activity-module--activityImage--3H34e";
export var header = "Activity-module--header--1JNsE";
export var image = "Activity-module--image--uMRSg";
export var addtoplan = "Activity-module--addtoplan--2trSI";
export var attrtext = "Activity-module--attrtext--10HQe";
export var subtitle = "Activity-module--subtitle--31tn_";
export var text = "Activity-module--text--_PqOu";
export var researchValidatedText = "Activity-module--researchValidatedText--206vO";
export var labelresearch = "Activity-module--labelresearch--w4_aQ";
export var labelresearchimg = "Activity-module--labelresearchimg--X4HEY";
export var activityContainer = "Activity-module--activityContainer--2l4DX";
export var growth = "Activity-module--growth--1DS4Q";
export var activityDescription = "Activity-module--activityDescription--2iP4i";
export var activityBenefit = "Activity-module--activityBenefit--2WdW_";
export var main = "Activity-module--main--2-_y7";
export var mainAlt = "Activity-module--mainAlt--3KJoF";
export var activityMethod = "Activity-module--activityMethod--2OAcX";
export var attContainer = "Activity-module--attContainer--J2NeC";
export var bgimage = "Activity-module--bgimage--1_U4w";
export var activityHeading = "Activity-module--activityHeading--3fAMV";
export var activityHeadingSource = "Activity-module--activityHeadingSource--Fcl5l";
export var buttoncontainer = "Activity-module--buttoncontainer--2cLFO";
export var goalTitle = "Activity-module--goalTitle--1n8yO";
export var goallbl1 = "Activity-module--goallbl1--RhuvK";
export var goallbl2 = "Activity-module--goallbl2--3RlQg";
export var goallbl3 = "Activity-module--goallbl3--2Xage";
export var goallbl = "Activity-module--goallbl--1eufh";
export var chipContainer = "Activity-module--chipContainer--1aeIW";
export var MuiChipLabel = "Activity-module--MuiChip-label--35u5w";
export var chip = "Activity-module--chip--E-xnX";
export var addCalendar = "Activity-module--addCalendar--1e7hy";
export var snackbarRoot = "Activity-module--snackbarRoot--28PRe";
export var frequency = "Activity-module--frequency--21-Lr";
export var saveButton = "Activity-module--saveButton--2Mxo8";
export var fab = "Activity-module--fab--1CC0m";
export var saveBtn = "Activity-module--saveBtn--3EFTY";
export var footer = "Activity-module--footer--1XEpT";
export var footersticky = "Activity-module--footersticky--2E8MA";